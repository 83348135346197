import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

import Sidebar from "../../components/sidebar/sidebar"
import Vinhos from "../../components/vinhos/vinhos"
import Footer from "../../components/footer/footer"
import SidebarMobile from '../../components/sidebar/mobile'
import WineFilter from '../../components/sidebar/wine-filter'

const VinhosPage = ({data}) => {
  const breakpoints = useBreakpoint();
  return(
    <Layout color="#150e04">
      <SEO title="Vinhos"/>
      {!breakpoints.mobile ?
        <Sidebar switchMenu="right" menuBtn/>
      :
        <SidebarMobile brown/>
      }
      {breakpoints.mobile &&
        <WineFilter white="isWhite"/>
      }
      <Vinhos data={data.vinhosJson}/>
      <Footer/>
    </Layout>
  )
}

export default VinhosPage


export const Json = graphql`
  query vinhos {
    vinhosJson{
      banners{
        docs{
          bairrada{
            banner{
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          dao{
            banner{
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          douro{
            banner{
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          setubal{
            banner{
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          palmela{
            banner{
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          porto{
            banner{
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          tejo{
            banner{
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          verde{
            banner{
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      docs{
        bairrada{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
          ano
        }
        dao{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
          ano
        }
        douro{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
          ano
        }
        setubal{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
          ano
        }
        palmela{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
          ano
        }
        porto{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
          ano
        }
        tejo{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          regiao
          ano
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        verde{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
          ano
        }
      }
    }
  }
`
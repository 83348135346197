import React from "react"
import styled from "styled-components"
import { device } from "../device"

import Banner from "./vinhosBanner"
import VinhosList from "./vinhosCardList"

const Vinhos = ({ data }) => {

  return(
    <StyledVinhos>
      <Banner data={data.banners.docs.bairrada} vinho='bairrada'/>
      <VinhosList data={data.docs.bairrada} vinho='bairrada'/>

      <Banner data={data.banners.docs.dao} vinho='dao'/>
      <VinhosList data={data.docs.dao} vinho='dao'/>

      <Banner data={data.banners.docs.douro} vinho='douro'/>
      <VinhosList data={data.docs.douro} vinho='douro'/>

      <Banner data={data.banners.docs.setubal} vinho='setubal'/>
      <VinhosList data={data.docs.setubal} vinho='setubal'/>

      <Banner data={data.banners.docs.palmela} vinho='palmela'/>
      <VinhosList data={data.docs.palmela} vinho='palmela'/>

      <Banner data={data.banners.docs.porto} vinho='porto'/>
      <VinhosList data={data.docs.porto} vinho='porto'/>

      <Banner data={data.banners.docs.tejo} vinho='tejo'/>
      <VinhosList data={data.docs.tejo} vinho='tejo'/>

      <Banner data={data.banners.docs.verde} vinho='verde'/>
      <VinhosList data={data.docs.verde}  vinho='verde'/>
    </StyledVinhos>
  )
}

export default Vinhos

const StyledVinhos = styled.div`
  margin-top:50px;

  @media ${device.desktop}{
    margin: 0 14px 100px 200px;
  }

  @media ${device.desktopL}{
    margin: 0 14px 100px 15.65%;
  }
`